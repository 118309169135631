<!--
 * @Description: 培训活动
 * @Author: wangqiannan
 * @Date: 2022-08-05 09:00:00
-->

<template>
  <div class="ind-crouse mt15 w_auto">
    <div>
      <div class="ind-title flex-between">
        <div class="ind-name">
          <i class="el-icon-caret-right"></i>
          <span class="font18 midt">{{item.floorName}}</span>
          <div class="ind-fl">
            <div
              v-for="(item,index) in gradeList"
              :key="index"
              :label="item.typeNum"
              @click="handleClick(item,index)"
            >
              <span
                class="index_subnav"
                :class="activeIndex == index ? 'active' : ''"
                >{{ item.typeName }}
                <div class="aa"></div
              ></span>
            </div>
          </div>
        </div>
        <div class="more">
          <span @click="peixunClick">全部</span>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <!-- <div v-for="(i,index) in gradeList" :key="index"> -->
        <Internet :items="typeIds" :pxvalue="item"></Internet>
      <!-- </div> -->
      
    </div>
  </div>
</template>

<script>
import Internet from "./training/InternetTraining";
export default {
  components: { Internet },
  props:{
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data() {
    return {
      token: localStorage.getItem("pc_token"),
      activeName: "second",
      gradeList: [],
      // number: "01",
      activeIndex: 0,
      page:1,
      typeIds:{}
    };
  },
  mounted(){
    this.getActivityTypeListApi()
  },
  methods: {
    handleClick(item,index) {
      this.activeIndex = index
      // this.number = item;
      this.typeIds = item
    },
    getActivityTypeListApi(){
      this.$api.getActivityTypeListApi({
        page:this.page,
        limit:this.item.showNumber,      
        channelId: this.$http.channelId,
        parentId: 0,
        token:this.token
      }).then(res => {
        if(res.code == 10000){
          this.gradeList = res.data
          this.typeIds = res.data[0]
        }
      })
    },
    peixunClick(){
      this.$router.push({
        path:'/activity/index'
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.active {
    color:green;
  }
.ind-crouse[data-v-ce40753e] {
  width: 100%;
  display: inline-block;
}
.mt15 {
  margin-top: 15px;
}
.ind-title {
  padding: 10px 0;
  margin-bottom: 15px;
  align-items: center;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.ind-name {
  display: flex;
  align-items: center;
}
.ind-name .midt {
  position: relative;
  margin-left: 5px;
  font-size: 18px;
}
.ind-fl {
  display: flex;
  margin-left: 20px;
}
.ind-fl span.index_subnav {
  padding: 3px 0px 5px;
  margin: 0 15px;
  position: relative;
  transition: 0.3s;
  font-size: 14px;
}
.ind-fl span {
  cursor: pointer;
}
.ind-fl span.index_subnav:hover {
  color: #3ce4bc;
}
.green {
  border-bottom: 1px solid #3ce4bc;
  color: #3ce4bc;
}

.aa {
  position: absolute;
  content: "";
  width: 1px;
  height: 10px;
  right: -15px;
  background: #d3d2d2;
  top: 50%;
  margin-top: -5px;
}
.aa:last-child{
  position: absolute;
  content: "";
  width: 0px;
  height: 10px;
  right: -15px;
  background: #d3d2d2;
  top: 50%;
  margin-top: -5px;
}
.more {
  font-size: 14px;
}
.ind-name ::v-deep .el-icon-caret-right {
  font-size: 25px;
}
</style>