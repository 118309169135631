import { render, staticRenderFns } from "./InternetTraining.vue?vue&type=template&id=7d36b5b4&scoped=true"
import script from "./InternetTraining.vue?vue&type=script&lang=js"
export * from "./InternetTraining.vue?vue&type=script&lang=js"
import style0 from "./InternetTraining.vue?vue&type=style&index=0&id=7d36b5b4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d36b5b4",
  null
  
)

export default component.exports